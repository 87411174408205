import React from "react";
import Layout, { PageWrapper } from "../../components/layout";
import { PageSection } from "../../styles/index/_components";
import "twin.macro";
import Powerful from "../../images/vectors/undraw_Powerful_re_frhr.svg";
import OfferSiteBlock from "../../components/blocks/offerSiteBlock";
import { Link } from "gatsby";
import PricesBlock from "../../components/blocks/pricesBlock";

const Title = "Pomoc psychologiczna";

const Desc =
  "Znajdujesz się w trudnej sytuacji natury psychicznej? Nie wiesz co się dzieje, jednak z dnia na dzień czujesz się gorzej i nie możesz sobie z tym poradzić? Jeśli tak, to trafiłeś w dobre miejsce, tutaj odnajdziesz pomoc.";

const Quote =
  "„Niewyrażone emocje nigdy nie umierają. Zostają zakopane żywcem, aby powrócić później w znacznie gorszej postaci.”";
const QuoteAuthor = "S. Freud";

const CTAText =
  "W momencie, gdy zdecydujesz się na swoją pierwszą konsultację psychologiczną, to na jednym do kilku spotkań, zajmiemy się omówieniem problemu z jakim przychodzisz";

const Prices = [
  "Konsultacja indywidualna psychologiczna: 150 zł",
  "Konsultacja pary: 200 zł za 80 minut",
];

const PomocPsychologiczna = () => {
  return (
    <Layout>
      <PageSection>
        <PageWrapper>
          <OfferSiteBlock
            quoteAuthor={QuoteAuthor}
            quote={Quote}
            title={Title}
            CTAText={CTAText}
            desc={Desc}
            prices={Prices}
            Image={Powerful}
          />
          <PricesBlock
            title="Konsultacja indywidualna psychologiczna"
            headlines={["Osoba", "Cena", "Czas"]}
            fills={[
              { name: "Justyna Barton", price: "170 zł", time: "50 minut" },
              { name: "Maciej Schmidt", price: "200 zł", time: "50 minut" },
              { name: "Julianna Graczyk", price: "200 zł", time: "50 minut" },
            ]}
          />

          <div tw="flex items-center justify-center p-8">
            <Link
              to={"/kontakt"}
              tw="transform animate-pulse  p-6 text-xl md:text-3xl border-4 border-four rounded-lg text-four hover:scale-110"
            >
              Umów się na wizytę
            </Link>
          </div>
        </PageWrapper>
      </PageSection>
    </Layout>
  );
};

export default PomocPsychologiczna;
